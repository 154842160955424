import Login from '../../components/login';
import Head from 'next/head';

export default function Index() {
  return (
    <>
      <Head>
        <title>Catchy ログイン</title>
      </Head>
      <Login isSignup={false} />
    </>
  );
}
